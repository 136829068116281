import { ExpandMore, RadioButtonChecked } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import expand from "../assets/images/expand.svg";
import collasp from "../assets/images/less.svg";

const accordionData = [
  {
    id: "panel1",
    title: "What is Vpickup?",
    content:
      "Vpickup is a delivery service that offers fast and convenient pickup and delivery of items such as food, groceries, laundry, cosmetics, electronics, and more. Whether you need something picked up or delivered, Vpickup has you covered with reliable and efficient service.",
  },
  {
    id: "panel2",
    title: "How does Vpickup work?",
    content:
      "Simply download the Vpickup app, select the items you need to be picked up or delivered, choose your preferred time and location, and our team will handle the rest. You can track your order in real time and communicate with your assigned Picker throughout the process.",
  },
  {
    id: "panel3",
    title: "What areas do you serve?",
    content:
      "Vpickup operates in several cities and is continually expanding. Please check the app or our website to confirm availability in your area.",
  },
  {
    id: "panel4",
    title: "Can I schedule deliveries in advance?",
    content:
      "Yes! Vpickup allows you to schedule pickups and deliveries for a time that is most convenient for you. Whether you need something immediately or later in the day, we’ve got you covered.",
  },
  {
    id: "panel5",
    title: "Is there a minimum order value?",
    content:
      "No, there is no minimum order value on Vpickup. You can order as much or as little as you need, making it easy to manage small or large requests.",
  },
  {
    id: "panel6",
    title: "How do I track my order?",
    content:
      "Once your order is placed, you can track it in real-time via the Vpickup app. You’ll receive updates on your order status, including estimated time of arrival, and you can communicate directly with your Picker for any specific requests or clarifications.",
  },
  {
    id: "panel7",
    title: "What safety measures are in place for deliveries?",
    content:
      "We prioritize the safety and hygiene of our customers and Pickers. Our Pickers follow strict hygiene protocols, and we offer no-contact deliveries for added peace of mind. Simply select the no-contact option at checkout.",
  },
  {
    id: "panel8",
    title: "What items can I have delivered through Vpickup?",
    content:
      "Vpickup handles a wide variety of items, including food, groceries, cosmetics, laundry, electronics, documents, and more. If you have a special request, feel free to contact us, and we’ll do our best to accommodate it.",
  },
  {
    id: "panel9",
    title: "Can I order from stores or restaurants outside my immediate area?",
    content:
      "Yes! Vpickup allows you to place orders from both nearby and distant locations, giving you access to a wide range of stores and restaurants.",
  },
  {
    id: "panel10",
    title: "What payment methods do you accept?",
    content:
      "We accept various payment methods, including major credit/debit cards, digital wallets, and in-app payments. All transactions are processed securely.",
  },
  {
    id: "panel11",
    title: "How do I report an issue with my order?",
    content:
      "If you encounter any issues with your order, you can report it directly through the Vpickup app or contact our customer support team. We are committed to resolving any issues quickly and ensuring a great experience.",
  },
  {
    id: "panel12",
    title: "Are there any delivery fees?",
    content:
      "Delivery fees may vary based on your location, the distance of the delivery, and the type of service you select. You can view the exact fee for your order during checkout before finalizing your purchase.",
  },
  {
    id: "panel13",
    title: "How do I contact customer service?",
    content:
      "You can reach our customer service team through the Vpickup app or by visiting our Contact Us page on the website. We are here to assist you with any questions or concerns.",
  },
  {
    id: "panel14",
    title: "Do you offer any promotions or discounts?",
    content:
      "Yes, we regularly offer promotions, cashback, and discounts. Keep an eye out for special offers in the app or on our website, and enjoy savings while using our service.",
  },
  {
    id: "panel15",
    title: "Can I tip my Picker?",
    content:
      "Tipping is optional, but greatly appreciated by our Pickers. You can add a tip during checkout or after your order is delivered.",
  },
];

export function FAQs() {
  const [expanded, setExpanded] = useState(null); // Set default expanded panel

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        // padding: { xs: "0 20px 0", md: "50px 140px 50px" },
        marginTop: {
          xs: "50px",
        },
        marginBottom: "50px",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          padding: { xs: "0 20px 0", md: "0 0 40px" },
          background: "rgba(104, 60, 140, 0.2)",
        }}
      >
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: "40px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "20px",
            paddingTop: "40px",
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Typography
          sx={{
            color: "#161C2D",
            fontSize: { xs: "16px", sm: "18px", md: "18px" },
            textAlign: "center",
            fontFamily: "Roboto",
            opacity: "0.7",
            fontWeight: 400,
            // marginBottom: "18px",
          }}
        >
          Most of your questions are answered here, but feel free to reach out
          if you need more help.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: "0 !important" }}>
        {accordionData.map((item) => (
          <Accordion
            key={item.id}
            expanded={expanded === item.id}
            onChange={handleChange(item.id)}
            sx={{
              background:
                expanded === item.id ? "#fff" : "rgba(104, 60, 140, 0.20)",
              boxShadow: "none",
              padding: { xs: "0 20px", md: "0 140px" },
              "&:before": {
                display: "none",
              },
              "&.Mui-expanded": {
                margin: 0,
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <IconButton>
                  {expanded === item.id ? (
                    <img src={collasp} width="30" alt="Collapse icon" />
                  ) : (
                    <img src={expand} width="30" alt="Expand icon" />
                  )}
                </IconButton>
              }
              aria-controls={`${item.id}-content`}
              id={`${item.id}-header`}
              sx={{
                display: "flex",
                alignItems: "center",
                borderBottom:
                  expanded === item.id
                    ? "none"
                    : "1px solid rgba(104, 60, 140, 0.20)",
              }}
            >
              <Box
                sx={{
                  marginRight: "10px",
                  mt: 1,
                  display: expanded === item.id ? "none" : "block",
                }}
              >
                <RadioButtonChecked
                  sx={{ color: "#683C8C", fontSize: "14px" }}
                />
              </Box>

              <Typography
                sx={{
                  color: expanded === item.id ? "#683C8C" : "#000",
                  fontSize: "24px",
                  fontWeight: 400,
                }}
              >
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: 400,
                  mb: "14px",
                }}
              >
                {item.content}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          padding: "40px",
          background: "rgba(104, 60, 140, 0.2)",
        }}
      ></Grid>
    </Grid>
  );
}
