import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  Backdrop,
  CircularProgress,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Close, East } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { $crud } from "../utils/CrudFactory";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 9999, // Ensure the loader appears on top of everything
    color: "#fff",
  },
  dialogPaper: {
    borderRadius: "16px !important",
    border: "none",
  },
}));

export function SubscribeEmail() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);
  const [dataProcessingChecked, setDataProcessingChecked] = useState(false);
  const [consentError, setConsentError] = useState("");
  const [dataProcessingError, setDataProcessingError] = useState("");
  const [loading, setLoading] = useState(false); // For showing the loader

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = () => {
    if (!email) {
      setEmailError("Email is required");
    } else if (!validateEmail(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
      setOpenDialog(true); // Open the modal only when the email is valid
    }
  };

  const handleDialogSubmit = async () => {
    if (!consentChecked) {
      setConsentError("You must agree to receive communications.");
    }
    if (!dataProcessingChecked) {
      setDataProcessingError("You must agree to store personal data.");
    }

    if (consentChecked && dataProcessingChecked) {
      setConsentError("");
      setDataProcessingError("");
      setLoading(true); // Show the loader

      try {
        const { data, type } = await $crud.post(`website/subscribers`, {
          email,
        });

        if (data) {
          setLoading(false); // Hide loader when API call is successful
          setOpenDialog(false); // Close the dialog
          setEmail("");
          setConsentChecked(false);
          setDataProcessingChecked(false);
        }

        if (type === "error") {
          setLoading(false); // Hide loader
          setOpenDialog(false); // Close the dialog on error
          setEmail("");
          setConsentChecked(false);
          setDataProcessingChecked(false);
        }
      } catch (error) {
        // Handle any error from the API
        console.error(error);
        setLoading(false); // Hide loader in case of error
        setOpenDialog(false); // Close the dialog on error
      }
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEmail("");
    setConsentChecked(false);
    setDataProcessingChecked(false);
    setConsentError("");
    setDataProcessingError("");
  };

  const handleCheckboxChange = (setChecked) => (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Grid
      container
      sx={{
        padding: { xs: "20px 20px", md: "56px 140px" },
        background: "#683C8C",
      }}
    >
      <Grid item xs={12} lg={6}>
        <Typography
          sx={{
            color: "#fff",
            fontFamily: "Roboto",
            fontSize: { xs: "28px", md: "32px" },
            fontWeight: 700,
          }}
        >
          Subscribe to our newsletter
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            margin: "0 auto",
            gap: "20px",
            paddingLeft: { lg: "100px" },
          }}
        >
          <Box sx={{ width: "70%" }}>
            <TextField
              name="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                color: "#1C1B1F",
                borderRadius: "8px",
                background: "#fff",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  padding: "6px",
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
              fullWidth
              size="small"
              variant="outlined"
            />
            <Typography
              sx={{
                fontSize: "12px",
                color: "red",
                paddingLeft: "4px",
                marginTop: "8px",
              }}
            >
              {emailError}
            </Typography>
          </Box>

          <Button
            variant="contained"
            sx={{
              padding: "14px 40px",
              background: "#fff",
              borderRadius: "8px",
              color: "#683C8C",
              textTransform: "capitalize",
              boxShadow: "none",
              fontFamily: "Inter",
              fontWeight: 400,
              "&:hover": {
                background: "#fff",
                color: "#683C8C",
              },
            }}
            onClick={handleSubmit}
          >
            Subscribe <East sx={{ ml: 1 }} />
          </Button>
        </Box>
      </Grid>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        maxWidth="md"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        sx={{ borderRadius: "20px", border: "none" }}
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogContent sx={{ padding: "30px 40px", position: "relative" }}>
          <IconButton
            onClick={handleCloseDialog}
            sx={{ position: "absolute", right: "10px", top: "10px" }}
          >
            <Close />
          </IconButton>

          <Typography sx={{ fontSize: "14px", marginBottom: "2px" }}>
            Email<span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            disabled
            sx={{
              color: "#1C1B1F",
              mb: 3,
              "& .MuiInputLabel-root": {
                color: "#1C1B1F !important",
              },
              "& .MuiOutlinedInput-root": {
                // "&:hover fieldset": {
                //   borderColor: (theme) => theme.palette.text.secondary,
                // },
                // "&.Mui-focused fieldset": {
                //   borderColor: "#1C1B1F",
                // },
              },
            }}
            size="small"
            fullWidth
            value={email}
            variant="outlined"
          />

          <Typography sx={{ fontSize: "1rem", marginBottom: "2px" }}>
            Pqxel Inc is committed to protecting and respecting your privacy,
            and we’ll only use your personal information to administer your
            account and to provide the products and services you requested from
            us. From time to time, we would like to contact you about our
            products and services, as well as other content that may be of
            interest to you. If you consent to us contacting you for this
            purpose, please tick below to say how you would like us to contact
            you:
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={consentChecked}
                onChange={handleCheckboxChange(setConsentChecked)}
                sx={{
                  "&.Mui-checked": {
                    color: "#683C8C",
                  },
                }}
              />
            }
            label={
              <Typography sx={{ fontSize: "1rem", marginBottom: "2px" }}>
                I agree to receive other communications from Pqxel Inc.
                <span style={{ color: "red" }}>*</span>
              </Typography>
            }
          />
          {consentError && (
            <Typography
              sx={{
                fontSize: "12px",
                color: "red",
                paddingLeft: "4px",
                marginTop: "8px",
              }}
            >
              {consentError}
            </Typography>
          )}

          <Typography sx={{ fontSize: "1rem", marginBottom: "2px" }}>
            In order to provide you the content requested, we need to store and
            process your personal data. If you consent to us storing your
            personal data for this purpose, please tick the checkbox below.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={dataProcessingChecked}
                onChange={handleCheckboxChange(setDataProcessingChecked)}
                sx={{
                  "&.Mui-checked": {
                    color: "#683C8C",
                  },
                }}
              />
            }
            label={
              <Typography sx={{ fontSize: "1rem", marginBottom: "2px" }}>
                I agree to allow Pqxel Inc to store and process my personal
                data.<span style={{ color: "red" }}>*</span>
              </Typography>
            }
          />
          {dataProcessingError && (
            <Typography
              sx={{
                fontSize: "12px",
                color: "red",
                paddingLeft: "4px",
                marginTop: "8px",
              }}
            >
              {dataProcessingError}
            </Typography>
          )}
          <Typography sx={{ fontSize: "1rem", marginBottom: "10px" }}>
            You can unsubscribe from these communications at any time. For more
            information on how to unsubscribe, our privacy practices, and how we
            are committed to protecting and respecting your privacy, please
            review our Privacy Policy.
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              disabled={!consentChecked || !dataProcessingChecked}
              onClick={handleDialogSubmit}
              sx={{
                backgroundColor: "#683C8C",
                color: "#fff",
                borderRadius: "8px",
                textTransform: "capitalize",
                padding: "8px 40px",
                "&:hover": {
                  backgroundColor: "#683C8C",
                },
              }}
            >
              Subscribe
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
