import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  HomePage,
  CategoryPage,
  PrivacyPolicy,
  TermsOfUse,
  PromoTerms,
  Refund,
  AcceptableItems,
  OutAreaPolicy,
  CoverageArea,
  About,
  MoreWays,
  Help,
  News,
  IOSApp,
  AndroidApp,
  WebApp,
  CommingSoon,
  UnderMaintainence,
  DrivePickUs,
  FAQs,
  PartnerWithUs,
  BusinessClientStory,
} from "../pages";
import { Navbar, Footer, RegularOffer, BannerCarousel } from "../components";
import PublicRoute from "./PublicRoute";
import ScrollToTop from "../components/ScrolltpTop";
import { BusinessOwnerCardSection } from "../components/BusinessOwnerCardSection";
import { $crud } from "../utils/CrudFactory";

const SimpleLayout = ({ children }) => {
  return <main>{children}</main>; // Only renders the content without Navbar, Footer, or RegularOffer
};

const Layout = ({ children }) => {
  const [offers, setOffers] = useState([]);

  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/website/regular-offers");
    return data;
  }

  useEffect(() => {
    retrieveData().then((res) => {
      setOffers(res.result);
    });
  }, []);
  return (
    <div>
      <RegularOffer />
      <BannerCarousel />
      <Navbar />
      <main
        style={{
          marginTop: offers.length > 0 ? "180px" : "140px",
        }}
      >
        {children}
      </main>
      <Footer />
    </div>
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* ========================== PUBLIC ROUTES ========================== */}
        <Route path="/" element={<PublicRoute />}>
          <Route
            index
            element={
              <Layout>
                <HomePage />
              </Layout>
            }
          />
          <Route
            path="client-stories"
            element={
              <Layout>
                <BusinessClientStory />
              </Layout>
            }
          />
          <Route
            path="all-client-stories"
            element={
              <Layout>
                <BusinessOwnerCardSection />
              </Layout>
            }
          />
          <Route
            path="privacy-policy"
            element={
              <Layout>
                <PrivacyPolicy />
              </Layout>
            }
          />
          <Route
            path="terms-of-use"
            element={
              <Layout>
                <TermsOfUse />
              </Layout>
            }
          />
          <Route
            path="promo-terms"
            element={
              <Layout>
                <PromoTerms />
              </Layout>
            }
          />
          <Route
            path="refund-cancellation"
            element={
              <Layout>
                <Refund />
              </Layout>
            }
          />
          <Route
            path="acceptable-items"
            element={
              <Layout>
                <AcceptableItems />
              </Layout>
            }
          />
          <Route
            path="out-area-policy"
            element={
              <Layout>
                <OutAreaPolicy />
              </Layout>
            }
          />
          <Route
            path="coverage-area"
            element={
              <Layout>
                <CoverageArea />
              </Layout>
            }
          />
          <Route
            path="about"
            element={
              <Layout>
                <About />
              </Layout>
            }
          />
          <Route
            path="more-ways"
            element={
              <Layout>
                <MoreWays />
              </Layout>
            }
          />
          <Route
            path="help"
            element={
              <Layout>
                <Help />
              </Layout>
            }
          />
          <Route
            path="news"
            element={
              <Layout>
                <News />
              </Layout>
            }
          />
          <Route
            path="ios-app"
            element={
              <Layout>
                <IOSApp />
              </Layout>
            }
          />
          <Route
            path="android-app"
            element={
              <Layout>
                <AndroidApp />
              </Layout>
            }
          />
          <Route
            path="web-app"
            element={
              <Layout>
                <WebApp />
              </Layout>
            }
          />

          <Route
            path="drive-pick-us"
            element={
              <Layout>
                <DrivePickUs />
              </Layout>
            }
          />
          <Route
            path="faqs"
            element={
              <Layout>
                <FAQs />
              </Layout>
            }
          />
          <Route
            path="partner-with-us"
            element={
              <Layout>
                <PartnerWithUs />
              </Layout>
            }
          />
          {/* Use SimpleLayout for Comming Soon and Under Maintenance Pages */}
          <Route
            path="comming-soon"
            element={
              <SimpleLayout>
                <CommingSoon />
              </SimpleLayout>
            }
          />
          <Route
            path="under-maintenance"
            element={
              <SimpleLayout>
                <UnderMaintainence />
              </SimpleLayout>
            }
          />
        </Route>
        {/* ========================== PUBLIC ROUTES ========================== */}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
