import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import logo from "../assets/images/purpleLogo.svg";
import fb from "../assets/images/fb.svg";
import insta from "../assets/images/insta.svg";
import yt from "../assets/images/yt.svg";
import tictok from "../assets/images/tictok.svg";
import { useNavigate } from "react-router-dom";

export function Footer() {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleNavigation = (path) => {
    navigate(path); // Use navigate to programmatically navigate to the path
  };

  return (
    <Grid
      className="footerArea"
      container
      sx={{
        padding: { xs: "20px 50px", lg: "60px 100px" },
        background: "#000",
      }}
    >
      <Grid item xs={12} md={3} sx={{ paddingLeft: { xs: "0", lg: "100px" } }}>
        <Typography
          sx={{
            color: "#fff",
            fontSize: "24px",
            fontFamily: "Roboto",
            fontWeight: 600,
            marginBottom: "30px",
          }}
        >
          Menu
        </Typography>
        <ul style={{ listStyle: "none", padding: 0 }}>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/")}
          >
            Home
          </li>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/more-ways")}
          >
            More Ways To Use
          </li>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/under-maintenance")}
          >
            News
          </li>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/about")}
          >
            About
          </li>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/help")}
          >
            Help
          </li>
        </ul>
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography
          sx={{
            color: "#fff",
            fontSize: "24px",
            fontFamily: "Roboto",
            fontWeight: 600,
            marginBottom: "30px",
          }}
        >
          How to access Vpickup
        </Typography>
        <ul style={{ listStyle: "none", padding: 0 }}>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/comming-soon")}
          >
            IOS App
          </li>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/comming-soon")}
          >
            Android App
          </li>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/comming-soon")}
          >
            Web App
          </li>
        </ul>
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography
          sx={{
            color: "#fff",
            fontSize: "24px",
            fontFamily: "Roboto",
            fontWeight: 600,
            marginBottom: "30px",
          }}
        >
          Frequent
        </Typography>
        <ul style={{ listStyle: "none", padding: 0 }}>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/help")}
          >
            Get Help
          </li>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/drive-pick-us")}
          >
            Drive/Pick with us
          </li>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/partner-with-us")}
          >
            Partner with us
          </li>
          {/* <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/comming-soon")}
          >
            FAQS
          </li> */}
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/coverage-area")}
          >
            Coverage Area
          </li>
        </ul>
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography
          sx={{
            color: "#fff",
            fontSize: "24px",
            fontFamily: "Roboto",
            fontWeight: 600,
            marginBottom: "30px",
          }}
        >
          Legal
        </Typography>
        <ul style={{ listStyle: "none", padding: 0 }}>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/privacy-policy")}
          >
            Privacy Policy
          </li>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/terms-of-use")}
          >
            Terms of use
          </li>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/promo-terms")}
          >
            Promo Terms
          </li>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/refund-cancellation")}
          >
            Refund and Cancellation
          </li>

          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/acceptable-items")}
          >
            Acceptable Items
          </li>
          <li
            style={{
              marginBottom: "30px",
              color: "#fff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => handleNavigation("/out-area-policy")}
          >
            Out of Area Policy
          </li>
        </ul>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "36px",
        }}
      >
        <Box sx={{ background: "#fff", height: "1px", width: "90%" }}></Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{ textAlign: { xs: "center", lg: "left" } }}
      >
        <Box
          sx={{
            paddingLeft: { xs: 0, lg: "150px" },
          }}
        >
          <img src={logo} />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{ textAlign: "center", mt: { xs: 4, lg: 0 } }}
      >
        <Typography
          sx={{
            color: "#fff",
            fontSize: "15px",
            fontFamily: "Roboto",
            fontWeight: 400,
            marginBottom: "43px",
          }}
        >
          © 2024 Vpickup Inc.
        </Typography>
        <Typography
          sx={{
            color: "#fff",
            fontSize: "11px",
            fontFamily: "Roboto",
            fontWeight: 400,
          }}
        >
          *For now, we’re only picking within reasonable distance of GTA
          (Greater Toronto Area). This will soon change!
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            display: "flex",
            height: "100px",
            justifyContent: { xs: "center", md: "end" },
            alignItems: "flex-end",
            gap: "14px",
            paddingRight: { xs: 0, lg: "150px" },
          }}
        >
          <a href="https://www.facebook.com/vpickuponsm" target="_blank">
            <img src={fb} />
          </a>
          <a href="https://www.instagram.com/vpickup_sm/" target="_blank">
            <img src={insta} />
          </a>
          <a href="https://www.youtube.com/@vpickupsm" target="_blank">
            <img src={yt} />
          </a>
          <a href="https://www.tiktok.com/@vpickupsm" target="_blank">
            {" "}
            <img src={tictok} />
          </a>
        </Box>
      </Grid>
    </Grid>
  );
}
