// LOCAL
// export const API_URL = "http://localhost:9000/api/";
// export const BASE_URL = "http://localhost:9000/";

//  QA
export const API_URL = "https://apiqa.vpickup.com/api/";
export const BASE_URL = "https://apiqa.vpickup.com/";

//  DEVELOPMENT
// export const API_URL = "https://apidev.vpickup.com/api/";
// export const BASE_URL = "https://apidev.vpickup.com/";




