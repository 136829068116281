import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export function DrivePickUs() {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleNavigation = (path) => {
    navigate(path); // Use navigate to programmatically navigate to the path
  };

  return (
    <Grid
      container
      sx={{
        padding: { xs: "0 20px 0", md: "50px 140px 0" },
        marginTop: {
          xs: "50px",
          lg: "100px",
        },
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: "40px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "30px",
          }}
        >
          Drive/Pick with us!
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "30px" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            opacity: "0.7",
            textAlign: "center",
            marginBottom: "40px",
          }}
        >
          We currently hand-picked our Pilot Drivers/Pickers for the first
          6months to try and test various programs. We are currently researching
          ways to pay our Pickers the maximum benefit so the time you spend
          delivering for us is really worth it. Unlike other riding apps our
          Picker safety, satisfaction is important for us.
          <br />
          <br />
          If you're interested to be picked for our next batch of Pickers, go to
          our support page and post your interest
        </Typography>
        <Grid item xs={12} sx={{ marginBottom: "40px", textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{
              padding: { xs: "8px 12px", md: "5px 80px" },
              background: "#683C8C",
              borderRadius: "8px",
              color: "#fff",
              textTransform: "capitalize",
              boxShadow: "none",
              fontFamily: "Roboto",
              fontWeight: 700,
              fontSize: "18px",
              "&:hover": {
                background: "#683C8C",
                color: "#fff",
              },
            }}
            onClick={() => handleNavigation("/help")}
          >
            Get Help
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        sx={{
          textAlign: "center",
          paddingRight: { lg: "80px" },
          marginBottom: "40px",
        }}
      >
        <Box
          sx={{
            background: "rgba(104, 60, 140, 0.1)",
            margin: "0 auto",
            padding: "20px",
            height: "230px",
            width: { xs: "100%", lg: "300px" },
          }}
        >
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "21px",
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            Inception
          </Typography>
          <Typography
            sx={{
              color: "#161C2D",
              fontFamily: "Roboto",
              fontSize: "17px",
              fontWeight: 400,
            }}
          >
            Started for a personal purpose and the founder matured it to
            everyone’s purpose by adding more layers to help the community as
            below.
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          textAlign: "center",
          padding: { lg: "0 80px" },
          marginBottom: "40px",
        }}
      >
        <Box
          sx={{
            background: "rgba(104, 60, 140, 0.1)",
            margin: "0 auto",
            height: "230px",
            padding: "20px",
            width: { xs: "100%", lg: "300px" },
          }}
        >
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "21px",
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            Our History
          </Typography>
          <Typography
            sx={{
              color: "#161C2D",
              fontFamily: "Roboto",
              fontSize: "17px",
              fontWeight: 400,
            }}
          >
            Founded in GTA Canada by the founder of our parent company for his
            personal use.
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          textAlign: "center",
          paddingLeft: { lg: "80px" },
          marginBottom: "40px",
        }}
      >
        <Box
          sx={{
            background: "rgba(104, 60, 140, 0.1)",
            margin: "0 auto",
            padding: "20px",
            height: "230px",
            width: { xs: "100%", lg: "300px" },
          }}
        >
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "21px",
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            Our Plan
          </Typography>
          <Typography
            sx={{
              color: "#161C2D",
              fontFamily: "Roboto",
              fontSize: "17px",
              fontWeight: 400,
            }}
          >
            Our plan is to help the ones who need our service and the ones
            driving around to help the needful.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
