import { AlignHorizontalCenter, East } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import carousel from "../assets/images/carousel1.svg";
import React, { useState, useEffect } from "react";
import { $crud } from "../utils/CrudFactory";
import { BASE_URL } from "../constants";

export function MoreWays() {
  const [data, setData] = useState([]);

  // Function to fetch data from API
  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/more-ways");
    return data;
  }

  useEffect(() => {
    retrieveData().then((res) => {
      setData(res.result);
    });
  }, []);
  return (
    <>
      <Grid
        container
        sx={{
          padding: { xs: "0 20px 80px", md: "50px 140px 100px" },
          marginTop: {
            xs: "50px",
            lg: "100px",
          },
        }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "48px",
              fontWeight: 700,
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            THERE ARE MORE WAYS TO USE VPICKUP
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ padding: { xs: 0, lg: "0 200px" }, textAlign: "center" }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: 400,
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            Read the stories of people and businesses using the Vpickup to ease
            their day-to-day life
          </Typography>
          <Typography
            sx={{
              color: "#683C8C",
              fontFamily: "Roboto",
              fontSize: "24px",
              fontWeight: 400,
              textAlign: "center",
              marginBottom: "40px",
            }}
          >
            More stories added as we identify these usecases
          </Typography>

          <Button
            variant="contained"
            sx={{
              padding: { xs: "8px 12px", md: "10px 20px" },
              background: "#683C8C",
              borderRadius: "12px",
              marginBottom: "100px",
              color: "#fff",
              textTransform: "capitalize",
              boxShadow: "none",
              fontFamily: "Roboto",
              fontWeight: 400,
              "&:hover": {
                background: "#683C8C",
                color: "#fff",
              },
            }}
          >
            Get The App
            <East sx={{ ml: 1 }} />
          </Button>
        </Grid>

        {data.map((m, i) => (
          <Grid
            key={i}
            item
            xs={12}
            lg={6}
            sx={{
              marginBottom: "60px",
            }}
          >
            <Box
              sx={{
                position: "relative",
                margin: "0 auto",
                width: { xs: "100%", lg: "568px" },
              }}
            >
              {/* Image with fixed height */}
              <img
                src={`${BASE_URL}moreWays/${m.image}`}
                style={{
                  borderRadius: "5px",
                  border: "3px solid #683C8C",
                  width: "100%",
                  height: "300px", // Keep the height fixed to 300px
                  display: "block",
                  objectFit: "cover", // Maintain aspect ratio while covering the box
                }}
                alt={m.title} // Alt text for accessibility
              />

              {/* Title */}
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "24px",
                  fontWeight: 600,
                  mt: 1,
                  mb: 1,
                  textTransform: "uppercase",
                }}
              >
                {m.title}
              </Typography>

              {/* Subtitle */}
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "20px",
                  fontWeight: 400,
                  marginBottom: "18px",
                }}
              >
                {m.tag}
              </Typography>

              {/* Why Section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  gap: "60px",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Roboto",
                    fontSize: "24px",
                    fontWeight: 600,
                  }}
                >
                  Case
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {m.why}
                </Typography>
              </Box>

              {/* Pickups Section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  gap: "20px",
                  marginBottom: "10px", // Add some margin to prevent content overlap
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Roboto",
                    fontSize: "24px",
                    fontWeight: 600,
                  }}
                >
                  Steps
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                  component="div" // Render the HTML content inside a div
                  dangerouslySetInnerHTML={{ __html: m.pickup }} // Render HTML content
                />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
