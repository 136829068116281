import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { East, PlayCircleOutline } from "@mui/icons-material";
import down from "../assets/images/down.svg";
import clientStory from "../assets/images/clientStory.svg";
import { BusinessOwnerCardSection } from "../components/BusinessOwnerCardSection";
import { DownloadApp, SubscribeEmail } from "../components";

export function BusinessClientStory() {
  const handleScrollDown = () => {
    const headerHeight = 80; // Height of the header
    window.scrollTo({
      top: window.innerHeight - headerHeight, // Subtract the header height
      behavior: "smooth", // Smooth scrolling
    });
  };
  return (
    <>
      <Grid
        container
        sx={{
          padding: { xs: "0 20px", md: "0" },
          height: { lg: "75vh" },
          position: "relative",
          zIndex: 1,
        }}
      >
        <Grid item xs={12} lg={5}>
          <Box
            sx={{
              paddingTop: { lg: "100px" },
              paddingLeft: { xs: "0", lg: "140px" },
              textAlign: { xs: "center", lg: "left" },
              zIndex: 1, // Keep it on top in banner area
              position: "relative", // To ensure content stays above the background
            }}
          >
            <Typography
              sx={{
                color: "#683C8C",
                fontSize: { xs: "24px", sm: "32px", md: "36px", lg: "40px" },
                fontFamily: "Roboto",
                fontWeight: 700,
                letterSpacing: "1.7px",
                mb: 2,
              }}
            >
              CLIENT STORIES <br />
              Business Clients
            </Typography>
            <Typography
              sx={{
                color: "#683C8C",
                fontSize: { xs: "16px", sm: "18px", md: "20px" },
                fontFamily: "Roboto",
                fontWeight: 400,
                marginBottom: "18px",
              }}
            >
              Read the stories of businesses using the Vpickup to ease their
              day-to-day life
            </Typography>
            <Button
              variant="contained"
              sx={{
                padding: { xs: "8px 12px", md: "10px 20px" },
                background: "#683C8C",
                borderRadius: "12px",
                color: "#fff",
                textTransform: "capitalize",
                boxShadow: "none",
                fontFamily: "Roboto",
                fontWeight: 400,
                "&:hover": {
                  background: "#683C8C",
                  color: "#fff",
                },
              }}
              onClick={handleScrollDown}
            >
              Get Started <East sx={{ ml: 1 }} />
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={7}
          sx={{
            marginTop: "60px",
            display: "flex",
            justifyContent: { xs: "center", lg: "end" },
            alignItems: "start",
            paddingRight: { xs: "0", lg: "140px" },
          }}
        >
          <Box>
            <img src={clientStory} />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            position: "absolute",
            bottom: "20px",
            width: "100%",
            textAlign: "center",
            zIndex: 1,
            display: { xs: "none", lg: "block" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              cursor: "pointer",
            }}
            onClick={handleScrollDown}
          >
            <img src={down} alt="scroll down icon" />
          </Box>
        </Grid>
      </Grid>
      <BusinessOwnerCardSection />
      {/* <DownloadApp /> */}
      {/* <SubscribeEmail /> */}
    </>
  );
}
