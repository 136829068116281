import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import logo from "../assets/images/logo.svg";
import logoPurple from "../assets/images/purpleLogo.svg";
import { Menu, Close } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { $crud } from "../utils/CrudFactory";

const pages = [
  { label: "Home", route: "/" },
  { label: "More ways to use", route: "/more-ways" },
  { label: "About", route: "/about" },
  { label: "Help", route: "/help" },
];

export function Navbar() {
  const navigate = useNavigate(); // Initialize the navigate function
  const [offers, setOffers] = useState([]);

  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/website/regular-offers");
    return data;
  }

  useEffect(() => {
    retrieveData().then((res) => {
      setOffers(res.result);
    });
  }, []);
  const handleNavigation = (path) => {
    navigate(path); // Use navigate to programmatically navigate to the path
  };
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const isActiveRoute = (route) => location.pathname === route;

  const list = () => (
    <Box
      sx={{ width: 250, position: "relative" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <IconButton
        sx={{ position: "absolute", top: 0, right: 0 }}
        size="large"
        aria-label="open drawer"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={toggleDrawer(false)}
        color="inherit"
      >
        <Close sx={{ color: "#683C8C" }} />
      </IconButton>
      <Box sx={{ padding: "16px" }}>
        <img src={logoPurple} height="60px" />
      </Box>
      <List>
        {pages.map((page) => (
          <ListItem button key={page.route}>
            <ListItemText
              onClick={() => handleNavigation(page.route)}
              primary={page.label}
              primaryTypographyProps={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 700,
                textDecoration: "none",
                color: isActiveRoute(page.route)
                  ? "rgba(104, 60, 140, 1)" // Active color
                  : "rgba(38, 38, 38, 0.6)", // Inactive color
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Grid
      container
      sx={{
        position: "fixed",
        top: offers.length > 0 ? "80px" : "40px",
        padding: { xs: "0 20px 0", md: "0 140px 0" },
        zIndex: 1000,
        height: "90px",
        backgroundColor: "#fff",
      }}
    >
      <Grid item xs={12}>
        {/* -------------------------- LARGE SCREEN MENU-------------------------- */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <Box>
            <img src={logo} height="90px" />
          </Box>
          <Box sx={{ display: "flex", gap: "30px" }}>
            {pages.map((page) => (
              <Typography
                key={page.route}
                sx={{
                  color: isActiveRoute(page.route)
                    ? "rgba(104, 60, 140, 1)" // Active color
                    : "rgba(38, 38, 38, 0.6)", // Inactive color
                  fontSize: "16px",
                  fontWeight: 700,
                  fontFamily: "Inter",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                component="a"
                onClick={() => handleNavigation(page.route)}
              >
                {page.label}
              </Typography>
            ))}
          </Box>

          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={() =>
                window.open(
                  "https://vpickup.freshdesk.com/support/login",
                  "_blank"
                )
              }
              variant="contained"
              sx={{
                padding: "10px 20px",
                background: "#683C8C",
                borderRadius: "12px",
                color: "#fff",
                textTransform: "capitalize",
                boxShadow: "none",
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: "14px",
                "&:hover": {
                  background: "#683C8C",
                  color: "#fff",
                },
              }}
            >
              Support Ticket
            </Button>
          </Box>
        </Box>
        {/* -------------------------- LARGE SCREEN MENU-------------------------- */}

        {/* -------------------------- MOBILE MENU-------------------------- */}
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "space-between",
            flexGrow: 1,
            width: "100%",
            padding: "14px 0",
          }}
        >
          <Box>
            <img src={logo} height="60px" />
          </Box>
          <Box>
            <IconButton
              size="large"
              aria-label="open drawer"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(true)}
              color="inherit"
            >
              <Menu sx={{ color: "#683C8C" }} />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              {list()}
            </Drawer>
          </Box>
        </Box>
        {/* -------------------------- MOBILE MENU-------------------------- */}
      </Grid>
    </Grid>
  );
}
