import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { DoItTogether, DownloadApp, SubscribeEmail } from "../components";
import { useNavigate } from "react-router-dom";

export function About() {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleNavigation = (path) => {
    navigate(path); // Use navigate to programmatically navigate to the path
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: { xs: "0 20px 0", md: "50px 140px 0" },
          marginTop: {
            xs: "50px",
            lg: "100px",
          },
        }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "40px",
              fontWeight: 700,
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            About us!
          </Typography>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: 700,
              opacity: "0.7",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            We're currently working to have this information available to you
            soon and we appreciate your patience.
          </Typography>
        </Grid> */}
        <Grid item xs={12} sx={{ marginBottom: "30px" }}>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: 400,
              opacity: "0.7",
              textAlign: "center",
              marginBottom: "40px",
            }}
          >
            Welcome to Vpickup, your go-to app for all your pickup and delivery
            needs! Whether you're sending or receiving laundry, food, cosmetics,
            documents, packages, electronics, or any other items, Vpickup makes
            it easy and convenient. Our professional team is dedicated to
            providing top-notch service, handling every request—big or
            small—with care and efficiency.
            <br />
            <br />
            Vpickup offers a wide range of services tailored to your lifestyle.
            From ordering food, groceries, and daily essentials to accessing
            late-night delivery services, we've partnered with top restaurants
            and stores in your city to bring you the best options. Craving
            something specific? With Vpickup, you can enjoy a variety of dishes
            like Biryani, Pizza, Pasta, Burritos, Burgers, Samosas, Sushi,
            Cakes, and more—all just a tap away.
            <br />
            <br />
            In addition to food, Vpickup lets you shop from nearby or distant
            supermarkets with scheduled delivery times to match your
            convenience. We ensure safety throughout the process, with our
            pickers following strict hygiene protocols, including the use of
            masks and the option for no-contact delivery. With no minimum order
            requirements, you can get exactly what you need—whether it's a
            last-minute birthday gift, daily essentials, or household goods—from
            your favorite grocery stores like Walmart, Superstore, and Loblaws,
            all at fair prices.
            <br />
            <br />
            Time is precious, and Vpickup understands that. You can schedule
            deliveries and select pickup and drop-off times that work best for
            you. Receive real-time updates on your order status, communicate
            easily with your Picker, and enjoy a seamless delivery experience.
            Vpickup even allows you to order from locations farther away, giving
            you access to more stores and services than ever before.
            <br />
            <br />
            To show our appreciation, we offer great deals and savings,
            including freebies, cashback, discounts, and exclusive promos. With
            Vpickup, you can save while enjoying fast, reliable service tailored
            to your needs.
          </Typography>
          <Grid item xs={12} sx={{ marginBottom: "68px", textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{
                padding: { xs: "8px 12px", md: "5px 80px" },
                background: "#683C8C",
                borderRadius: "8px",
                color: "#fff",
                textTransform: "capitalize",
                boxShadow: "none",
                fontFamily: "Roboto",
                fontWeight: 700,
                fontSize: "18px",
                "&:hover": {
                  background: "#683C8C",
                  color: "#fff",
                },
              }}
              onClick={() => handleNavigation("/help")}
            >
              Get Help
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            textAlign: "center",
            paddingRight: { lg: "80px" },
            marginBottom: "40px",
          }}
        >
          <Box
            sx={{
              background: "rgba(104, 60, 140, 0.1)",
              padding: "20px",
              margin: "0 auto",
              height: "230px",
              width: { xs: "100%", lg: "300px" },
            }}
          >
            <Typography
              sx={{
                color: "rgba(104, 60, 140, 1)",
                fontFamily: "Roboto",
                fontSize: "21px",
                fontWeight: 700,
                marginBottom: "20px",
              }}
            >
              Inception
            </Typography>
            <Typography
              sx={{
                color: "#161C2D",
                fontFamily: "Roboto",
                fontSize: "17px",
                fontWeight: 400,
              }}
            >
              Started for a personal purpose and the founder matured it to
              everyone’s purpose by adding more layers to help the community as
              below.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            textAlign: "center",
            padding: { lg: "0 80px" },
            marginBottom: "40px",
          }}
        >
          <Box
            sx={{
              background: "rgba(104, 60, 140, 0.1)",
              height: "230px",
              padding: "20px",
              width: { xs: "100%", lg: "300px" },
              margin: "0 auto",
            }}
          >
            <Typography
              sx={{
                color: "rgba(104, 60, 140, 1)",
                fontFamily: "Roboto",
                fontSize: "21px",
                fontWeight: 700,
                marginBottom: "20px",
              }}
            >
              Our History
            </Typography>
            <Typography
              sx={{
                color: "#161C2D",
                fontFamily: "Roboto",
                fontSize: "17px",
                fontWeight: 400,
              }}
            >
              Founded in GTA Canada by the founder of our parent company for his
              personal use.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            textAlign: "center",
            paddingLeft: { lg: "80px" },
            marginBottom: "40px",
          }}
        >
          <Box
            sx={{
              background: "rgba(104, 60, 140, 0.1)",
              padding: "20px",
              margin: "0 auto",
              height: "230px",
              width: { xs: "100%", lg: "300px" },
            }}
          >
            <Typography
              sx={{
                color: "rgba(104, 60, 140, 1)",
                fontFamily: "Roboto",
                fontSize: "21px",
                fontWeight: 700,
                marginBottom: "20px",
              }}
            >
              Our Plan
            </Typography>
            <Typography
              sx={{
                color: "#161C2D",
                fontFamily: "Roboto",
                fontSize: "17px",
                fontWeight: 400,
              }}
            >
              Our plan is to help the ones who need our service and the ones
              driving around to help the needful.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <DoItTogether />
      <DownloadApp />
      <SubscribeEmail />
    </>
  );
}
