import React, { useRef } from "react";
import Router from "./routers/Router.jsx";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, Button } from "@mui/material";
import { createAppTheme } from "./theme/theme";
import { SnackbarProvider } from "notistack";

const App = () => {
  const snackbarProvider = useRef();
  const mode = "light"; // This can be dynamically set, e.g., from a context or state
  const theme = createAppTheme(mode);
  return (
    <>
      <SnackbarProvider
        ref={snackbarProvider}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        maxSnack={3}
        action={(snackbarId) => (
          <Button
            size="small"
            color="inherit"
            onClick={() => snackbarProvider.current.closeSnackbar(snackbarId)}
          >
            Dismiss
          </Button>
        )}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router />
        </ThemeProvider>
      </SnackbarProvider>
    </>
  )
}

export default App