import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import { $crud } from "../utils/CrudFactory";

export function BannerCarousel() {
  const [offers, setOffers] = useState([]);

  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/website/regular-offers");
    return data;
  }

  useEffect(() => {
    retrieveData().then((res) => {
      setOffers(res.result);
    });
  }, []);

  return (
    <Grid
      container
      sx={{
        position: "fixed",
        top: "0", // Conditionally set top based on offers
        background: "#fff",
        height: "40px",
        padding: { xs: "0", md: "0 0 10px" },
        zIndex: 1200,
      }}
    >
      <Grid item xs={12} sx={{ background: "black", padding: "8px" }}>
        <Marquee
          autoFill
          gradient={false} // Disable gradient effect for continuous scrolling
          speed={50} // Adjust the speed of the scrolling
          pauseOnHover={true} // Pause the marquee on hover
        >
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Roboto",
              color: "#fff",
              fontSize: "16px",
              display: "inline", // Ensure elements are displayed inline
              marginRight: "90px", // Add space between offers
            }}
          >
            The Vpickup App is currently expected to launch by 30th Apr 2025.
            Watch this space, subscribe and follow us on social media for early
            registration offers!
          </Typography>
        </Marquee>
      </Grid>
    </Grid>
  );
}
