import React, { useState } from "react";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import { PlayCircleOutline } from "@mui/icons-material";
import youtubeVideoThumbnail from "../assets/images/youtubeVideoThumbnail.png";

export function YoutubeVideoSection() {
  const [open, setOpen] = useState(false);
  const videoId = "V3UwjxhbNR8"; // Replace with your video ID
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`; // Thumbnail URL

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: { xs: "0 20px 0", md: "0 140px 0" },
        marginTop: {
          xs: "50px",
        },
        marginBottom: "50px",
      }}
    >
      <Grid item xs={12} sx={{ marginBottom: "70px" }}>
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: "40px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Have you ever been in any of these situations?
        </Typography>
        <Typography
          sx={{
            color: "#161C2D",
            fontSize: { xs: "16px", sm: "18px", md: "18px" },
            textAlign: "center",
            fontFamily: "Roboto",
            opacity: "0.7",
            fontWeight: 400,
            // marginBottom: "18px",
          }}
        >
          These are someways we foresee you using the Vpickup App, but the
          possibilities are endless.
          <br /> Keep looking at our “More Ways To Use
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center", // Change to 'center' for vertical alignment
            gap: "40px",
            marginBottom: "80px",
          }}
        >
          <IconButton
            sx={{
              background: "rgba(104, 60, 140, 0.05)",
              color: "#683c8c",
              borderRadius: "50%",
              width: "45px", // Ensure the button is a square
              height: "45px", // Ensure the button is a square
              padding: "10px",
              fontSize: "16px",
              "&:hover": {
                color: "#fff",
                background: "#683c8c",
              },
            }}
          >
            1
          </IconButton>
          <Typography
            sx={{
              color: "#161C2D",
              fontSize: { xs: "16px", sm: "18px", md: "20px" },
              fontFamily: "Roboto",
              fontWeight: 400,
            }}
          >
            <b>Easy Booking :</b> Choose any time slot, including weekends or
            holidays booking
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center", // Change to 'center' for vertical alignment
            marginBottom: "80px",
            gap: "40px",
          }}
        >
          <IconButton
            sx={{
              background: "rgba(104, 60, 140, 0.05)",
              color: "#683c8c",
              borderRadius: "50%",
              width: "45px", // Ensure the button is a square
              height: "45px", // Ensure the button is a square
              padding: "10px",
              fontSize: "16px",
              "&:hover": {
                color: "#fff",
                background: "#683c8c",
              },
            }}
          >
            2
          </IconButton>
          <Typography
            sx={{
              color: "#161C2D",
              fontSize: { xs: "16px", sm: "18px", md: "20px" },
              fontFamily: "Roboto",
              fontWeight: 400,
              // marginBottom: "18px",
            }}
          >
            <b>Instant Pricing :</b> Check prices in the app and calculate your
            cost before placing an order Price!
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center", // Change to 'center' for vertical alignment
            gap: "40px",
          }}
        >
          <IconButton
            sx={{
              background: "rgba(104, 60, 140, 0.05)",
              color: "#683c8c",
              borderRadius: "50%",
              width: "45px", // Ensure the button is a square
              height: "45px", // Ensure the button is a square
              padding: "10px",
              fontSize: "16px",
              "&:hover": {
                color: "#fff",
                background: "#683c8c",
              },
            }}
          >
            3
          </IconButton>
          <Typography
            sx={{
              color: "#161C2D",
              fontSize: { xs: "16px", sm: "18px", md: "20px" },
              fontFamily: "Roboto",
              fontWeight: 400,
              // marginBottom: "18px",
            }}
          >
            <b>24*7 Support :</b> Get round-the-clock support if you need help
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          display: "flex",
          justifyContent: { xs: "center", lg: "end" },
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            position: "relative",
            borderRadius: "8px",
            width: { xs: "80%", md: "80%" },
            height: { xs: "auto", lg: "auto" },
          }}
        >
          <img
            src={youtubeVideoThumbnail} // Thumbnail URL
            alt="Video Thumbnail"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
          <IconButton
            onClick={handleOpen}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              background: "rgba(104, 60, 140, 0.2)",
              borderRadius: "50%",
              padding: "10px",
              color: "rgba(104, 60, 140, 1)",
              "&:hover": {
                background: "rgba(104, 60, 140, 0.2)",
              },
            }}
          >
            <PlayCircleOutline sx={{ fontSize: "4rem" }} />
          </IconButton>
          <Modal
            open={open}
            sx={{ width: "100%" }}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: "90%", sm: "80%", md: "70%", lg: "60%" },
                height: { xs: "50%", sm: "60%", md: "70%", lg: "80%" },
                bgcolor: "background.paper",
                borderRadius: "10px",
                boxShadow: 24,
                p: 0,
              }}
            >
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1`} // Embed URL with autoplay
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
          </Modal>
        </Box>
      </Grid>
    </Grid>
  );
}
