import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import carousel from "../assets/images/carousel1.svg";
import { East } from "@mui/icons-material";
import { $crud } from "../utils/CrudFactory";
import { useNavigate, useLocation } from "react-router-dom"; // Import necessary hooks
import { BASE_URL } from "../constants";
import { DownloadApp } from "./DownloadApp";
import { SubscribeEmail } from "./SubscribeEmail";

export function BusinessOwnerCardSection() {
  const [data, setData] = useState([]);
  const navigate = useNavigate(); // Hook to navigate between routes
  const location = useLocation(); // Hook to get current route
  const handleNavigation = (path) => {
    navigate(path); // Use navigate to programmatically navigate to the path
  };
  // Function to fetch data from API
  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/business-client-stories");
    return data;
  }

  useEffect(() => {
    retrieveData().then((res) => {
      setData(res.result);
    });
  }, []);

  // Function to handle redirection to 'all-client-stories' route
  const handleSeeMore = () => {
    navigate("/all-client-stories", { state: { data } }); // Pass full data array to the route
  };
  // Filter to get only objects where 'special' is true
  const specialData = data.filter((item) => item.special === true);

  // Show first 2 special items on '/client-stories' route, otherwise show all
  const displayedData =
    location.pathname === "/client-stories" ? specialData.slice(0, 2) : data;
  return (
    <>
      <Grid
        container
        sx={{
          padding: { xs: "0 20px 80px", md: "50px 140px 100px" },
          marginTop: {
            xs: "50px",
            lg: "100px",
          },
        }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "40px",
              fontWeight: 700,
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Are you a business owner?
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ padding: { xs: 0, lg: "0 200px" } }}>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: 400,
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            If you’re a restaurant owner stay competitive by offering your
            regular store prices online which you may have increased to cover
            the food delivery apps margins which are charged both to you and the
            client. We don’t charge you anything extra as we collect reasonable
            amounts from the customer/client.
          </Typography>
          <Typography
            sx={{
              color: "#683C8C",
              fontFamily: "Roboto",
              fontSize: "24px",
              fontWeight: 400,
              textAlign: "center",
              marginBottom: "80px",
            }}
          >
            More stories coming soon on our stories page for businesses
          </Typography>
        </Grid>

        {/* Map over displayed data to show the cards */}
        {displayedData.map((item, index) => (
          <Grid item xs={12} md={6} key={index} sx={{ marginBottom: "60px" }}>
            <Box
              sx={{
                position: "relative",
                margin: "0 auto",
                width: { xs: "100%", lg: "450px" },
              }}
            >
              <Box
                sx={{ position: "relative", width: "100%", height: "300px" }}
              >
                <Box
                  sx={{
                    background: "#683C8C",
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontSize: "40px",
                    fontWeight: 700,
                    position: "absolute",
                    top: "50%",
                    left: 0,
                    transform: "translateY(-50%)",
                    zIndex: 2,
                    width: "auto",
                  }}
                >
                  {item.label}
                </Box>
                <img
                  src={item.image}
                  style={{
                    borderRadius: "5px",
                    border: "3px solid #683C8C",
                    width: "100%",
                    height: "100%",
                    display: "block",
                    objectFit: "cover",
                  }}
                  alt={item.label}
                />
              </Box>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: 700,
                  marginBottom: "10px",
                  mt: 1,
                  textTransform: "uppercase",
                }}
              >
                {item.subTitle}
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "24px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                }}
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "20px",
                  fontWeight: 400,
                  marginBottom: "10px",
                }}
              >
                {item.description}
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "20px",
                  fontWeight: 400,
                  marginLeft: "-20px",
                }}
                component="div" // Use "div" to wrap the HTML content
                dangerouslySetInnerHTML={{ __html: item.details }} // Render HTML content
              />
            </Box>
          </Grid>
        ))}

        {/* 'See More' Button */}
        {location.pathname === "/client-stories" && (
          <Grid item xs={12} sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              sx={{
                padding: { xs: "8px 12px", md: "10px 20px" },
                background: "#683C8C",
                borderRadius: "12px",
                color: "#fff",
                textTransform: "capitalize",
                boxShadow: "none",
                fontFamily: "Roboto",
                fontWeight: 400,
                "&:hover": {
                  background: "#683C8C",
                  color: "#fff",
                },
              }}
              onClick={handleSeeMore} // Handle redirection
            >
              See More <East sx={{ ml: 1 }} />
            </Button>
          </Grid>
        )}
      </Grid>
      <DownloadApp />
      <SubscribeEmail />
    </>
  );
}
