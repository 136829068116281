import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import { $crud } from "../utils/CrudFactory";

export function RegularOffer() {
  const [offers, setOffers] = useState([]);

  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/website/regular-offers");
    return data;
  }

  useEffect(() => {
    retrieveData().then((res) => {
      setOffers(res.result);
    });
  }, []);

  // Render nothing if there are no offers
  if (offers.length === 0) return null;

  return (
    <Grid
      container
      sx={{
        position: "fixed",
        top: 0,
        background: "#fff",
        height: "40px",
        zIndex: 1200,
        backgroundColor: "#fff",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{ background: "rgba(104, 60, 140, 1)", padding: "8px" }}
      >
        <Marquee
          gradient={false} // Disable gradient effect for continuous scrolling
          speed={50} // Adjust the speed of the scrolling
          pauseOnHover={true} // Pause the marquee on hover
        >
          {offers.map((offer, index) => (
            <Typography
              key={index}
              sx={{
                textAlign: "center",
                fontFamily: "Roboto",
                color: "#fff",
                fontSize: "16px",
                display: "inline", // Ensure elements are displayed inline
                marginRight: "30px", // Add space between offers
              }}
            >
              {offer.discountDetails}&nbsp;&nbsp;{offer.offerCode}
            </Typography>
          ))}
        </Marquee>
      </Grid>
    </Grid>
  );
}
