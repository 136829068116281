import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import google from "../assets/images/google.svg";
import googleImg from "../assets/images/googleImg.svg";
import appstoreImg from "../assets/images/appstoreImg.svg";
import { DoItTogether, SubscribeEmail } from "../components";

export function AndroidApp() {
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          position: "relative",
          overflow: "hidden",
          background: "rgba(104, 60, 140, 0.2)",
          // height: "100vh",
          paddingTop: "46px",
        }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: { xs: "28px", md: "32px" },
              color: "#683C8C",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Let us run your errands like you do...
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "18px", md: "20px" },
              color: "#161C2D",
              fontWeight: 400,
              textAlign: "center",
              opacity: "0.7",
            }}
          >
            Download it on Android
          </Typography>
          <Box
            sx={{
              // width: "60%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              paddingTop: "60px",
            }}
          >
            <Box sx={{ width: { xs: "100px", md: "270px" } }}>
              <img src={google} style={{ width: "inherit" }} />
            </Box>
          </Box>

          <Box
            sx={{
              width: "60%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              // paddingTop: "20px",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-14px",
              }}
            >
              <Box sx={{ width: { xs: "150px", md: "100%" } }}>
                <img src={googleImg} style={{ width: "inherit" }} />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-14px",
              }}
            >
              <Box sx={{ width: { xs: "150px", md: "100%" } }}>
                <img src={appstoreImg} style={{ width: "inherit" }} />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <DoItTogether />
      <SubscribeEmail />
    </>
  );
}
