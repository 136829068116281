import {
  Box,
  Grid,
  Typography,
  IconButton,
  Modal,
  Button,
  DialogContent,
  Dialog,
  Backdrop,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import {
  Close,
  East,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import "swiper/css";
import "swiper/css/navigation";
import carousel1 from "../../assets/images/carousel1.svg";
import carousel2 from "../../assets/images/carousel2.svg";
import { $crud } from "../../utils/CrudFactory";
import { BASE_URL } from "../../constants";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 999,
    color: "#fff",
    backdropFilter: "blur(2px)", // Adjust blur strength as needed
  },
  dialogPaper: {
    borderRadius: "16px !important", // Set border radius to 0
    border: "none",
  },
  textFieldRoot: {
    "& .MuiInputLabel-root": {
      color: "#8D8D8E !important", // Adding !important to ensure the color overrides
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Initial outlined border color
      },
      "&:hover fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Change outlined border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(103, 60, 142, 1)", // Change outlined border color when focused
      },
    },
  },
}));

export function OfferCarousel() {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleNavigation = (path) => {
    navigate(path); // Use navigate to programmatically navigate to the path
  };
  const classes = useStyles();
  const [carouselData, setCarouselData] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null); // State to hold the selected offer
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const swiperRef = useRef(null);

  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/website/help-offers");
    return data;
  }

  useEffect(() => {
    retrieveData().then((res) => {
      setCarouselData(res.result);
    });
  }, []);

  const sortedCarouselData = carouselData.sort(
    (a, b) => a.listingPosition - b.listingPosition
  );

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleLearnMoreClick = (offer) => {
    setSelectedOffer(offer); // Set the clicked offer details
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOffer(null); // Clear the selected offer when closing
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        marginTop: "100px",
        padding: { xs: "0 20px", md: "0 140px" },
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: "32px",
            fontWeight: 600,
            mb: 1,
          }}
        >
          Discounts For Everyone In Need!
        </Typography>
        <Typography
          sx={{
            color: "#683C8C",
            fontSize: { xs: "16px", sm: "18px", md: "20px" },
            fontFamily: "Roboto",
            fontWeight: 400,
          }}
        >
          It emphasizes the importance of making essential goods and services
          accessible to those who need them most.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            width: "100%",
            marginTop: "80px",
            position: "relative",
          }}
        >
          {carouselData.length === 0 ? (
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Roboto",
                color: "black",
                fontSize: "16px",
              }}
            >
              No offers yet
            </Typography>
          ) : (
            <>
              <Swiper
                loop={true}
                navigation={false}
                modules={[Navigation]}
                className="mySwiper"
                breakpoints={{
                  320: { slidesPerView: 1, spaceBetween: 20 },
                  1024: { slidesPerView: 2, spaceBetween: 20 },
                  1500: { slidesPerView: 3, spaceBetween: 20 },
                }}
                speed={600}
                ref={swiperRef}
              >
                {sortedCarouselData.map((item, i) => (
                  <SwiperSlide key={i}>
                    <Box
                      sx={{
                        width: "350px",
                        height: "300px",
                        margin: "auto",
                        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "10px",
                        position: "relative",
                        overflow: "hidden",
                        backgroundColor: "#fff",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          fontFamily: "Roboto",
                          fontSize: "20px",
                          fontWeight: 700,
                          mb: 2,
                        }}
                      >
                        {item.offerName}
                      </Typography>

                      <Box
                        sx={{
                          borderRadius: "10px",
                          width: "100%",
                          height: "165px",
                          // display: "flex",
                          // justifyContent: "start",
                          // justifyContent: { xs: "start", md: "center" },
                          // alignItems: "center",
                          marginBottom: "25px",
                        }}
                      >
                        <img
                          src={`${BASE_URL}helpOffers/${item.bannerImg}`}
                          alt={item.title}
                          style={{
                            maxWidth: "100%",
                            width: "100%",
                            maxHeight: "100%",
                            // objectFit: "cover",
                          }}
                        />
                      </Box>

                      <Typography
                        sx={{
                          color: "#4F4F4F",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: 400,
                          opacity: "0.7",
                        }}
                      >
                        {item.discountDetails}
                      </Typography>

                      {/* Learn more section */}
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => handleLearnMoreClick(item)} // Handle click event
                      >
                        <Typography
                          sx={{
                            color: "#683C8C",
                            fontFamily: "Roboto",
                            fontSize: "17px",
                            fontWeight: 400,
                          }}
                        >
                          Learn more
                        </Typography>
                        <East sx={{ mr: 2, color: "#683C8C" }} />
                      </Box>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* Navigation Buttons */}
              <Box
                position="absolute"
                top="45%"
                sx={{ left: { xs: "20px", lg: "-40px" } }}
                zIndex={10}
                transform="translateY(-50%)"
                display="flex"
                alignItems="center"
              >
                <IconButton
                  onClick={goPrev}
                  disabled={carouselData.length <= 1}
                  sx={{
                    background: "#683c8c",
                    color: "#fff",
                    padding: "10px",
                    "&:hover": {
                      color: "#fff",
                      background: "#683c8c",
                    },
                  }}
                >
                  <KeyboardArrowLeft />
                </IconButton>
              </Box>
              <Box
                sx={{ right: { xs: "20px", lg: "-40px" } }}
                position="absolute"
                top="45%"
                zIndex={10}
                transform="translateY(-50%)"
                display="flex"
                alignItems="center"
              >
                <IconButton
                  onClick={goNext}
                  disabled={carouselData.length <= 1}
                  sx={{
                    color: "#fff",
                    background: "#683c8c",
                    padding: "10px",
                    "&:hover": {
                      color: "#fff",
                      background: "#683c8c",
                    },
                  }}
                >
                  <KeyboardArrowRight />
                </IconButton>
              </Box>
            </>
          )}
        </Box>
      </Grid>

      {/* Modal for showing offer details */}
      <>
        <Backdrop className={classes.backdrop} open={isModalOpen} />
        <Dialog
          open={isModalOpen}
          onClose={handleCloseModal}
          maxWidth="md"
          fullWidth
          classes={{ paper: classes.dialogPaper }}
          sx={{ borderRadius: "20px", border: "none" }}
        >
          <DialogContent sx={{ padding: "40px", position: "relative" }}>
            <IconButton
              onClick={handleCloseModal}
              sx={{ position: "absolute", right: "10px", top: "10px" }}
            >
              <Close />
            </IconButton>
            {selectedOffer && (
              <>
                <Typography
                  variant="h6"
                  component="h2"
                  mb={2}
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontSize: "28px",
                    color: "rgba(104, 60, 140, 1)",
                  }}
                >
                  {selectedOffer.offerName}
                </Typography>
                <Box
                  sx={{
                    borderRadius: "10px",
                    width: "100%",
                    height: "165px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "25px",
                  }}
                >
                  <img
                    src={`${BASE_URL}helpOffers/${selectedOffer.bannerImg}`}
                    alt={selectedOffer.title}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box
                  mb={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography sx={{ color: "rgba(104, 60, 140, 1)" }}>
                      <b>Discount Details </b>
                    </Typography>

                    <Typography
                      sx={{
                        color: "rgba(38, 38, 38)",
                      }}
                      mb={2}
                    >
                      {selectedOffer.discountDetails}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    sx={{
                      padding: { xs: "8px 12px", md: "5px 30px" },
                      background: "#683C8C",
                      borderRadius: "8px",
                      color: "#fff",
                      textTransform: "capitalize",
                      boxShadow: "none",
                      fontFamily: "Roboto",
                      fontSize: "18px",
                      "&:hover": {
                        background: "#683C8C",
                        color: "#fff",
                      },
                    }}
                    onClick={() => handleNavigation("/help")}
                  >
                    Contact Us
                  </Button>
                </Box>
                <Box mb={2}>
                  <Typography sx={{ color: "rgba(104, 60, 140, 1)" }}>
                    <b>Offer Details </b>
                  </Typography>

                  <Typography
                    sx={{
                      color: "rgba(38, 38, 38)",
                      background: "transparent",
                    }}
                    mb={2}
                    dangerouslySetInnerHTML={{
                      __html: selectedOffer.offerDetails,
                    }}
                  />
                </Box>
              </>
            )}
          </DialogContent>
        </Dialog>
      </>
    </Grid>
  );
}
