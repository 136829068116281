import { Grid, Typography, Box, Button } from "@mui/material";
import commingSoonBg from "../assets/images/comingSoonBg.svg";
import undermaintain from "../assets/images/undermaintain.png";
import React from "react";
import fb from "../assets/images/fbPurple.svg";
import insta from "../assets/images/instaPurple.svg";
import tictok from "../assets/images/tictokPurple.svg";
import yt from "../assets/images/linkedPurple.svg";
import home from "../assets/images/home.svg";
import notf from "../assets/images/notf.svg";
import share from "../assets/images/share.svg";
import { useNavigate } from "react-router-dom";

export function UnderMaintainence() {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleNavigation = (path) => {
    navigate(path); // Use navigate to programmatically navigate to the path
  };
  return (
    <Grid
      container
      sx={{
        backgroundImage: `url(${commingSoonBg})`, // Set the background image
        backgroundSize: "cover", // Ensure the background covers the entire area
        backgroundPosition: "center", // Center the image
        height: "100vh", // Full viewport height
        padding: { xs: "32px", md: "112px 120px" }, // Responsive padding for main container
        display: "flex", // Enable centering content
        alignItems: "center", // Vertically center content
        justifyContent: "center", // Horizontally center content
        textAlign: "center", // Text in the center
      }}
    >
      {/* Sub-container for centered content */}
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#683C8C", // Background color for the sub-container
          padding: { xs: "20px", md: "50px" }, // Responsive padding for sub-container
          borderRadius: "20px", // Optional: rounded corners for the box
          width: { xs: "100%", sm: "80%", md: "100%" }, // Responsive width
          minHeight: "70vh", // Set the minimum height for the sub-container
          display: "flex", // Flexbox to center the content
          flexDirection: "column", // Stack items vertically
          alignItems: "center", // Center items horizontally
          justifyContent: "center", // Center items vertically
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: { xs: "10px", lg: "30px" }, // Adjust top offset for responsiveness
            left: { xs: "10px", lg: "30px" }, // Adjust left offset for responsiveness
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "start" },
              gap: { xs: "6px", lg: "14px" },
            }}
          >
            <Box
              onClick={() => handleNavigation("/")}
              sx={{
                cursor: "pointer",
                width: { xs: "20px", lg: "30px" }, // Set width 20px for small, 30px for large screens
              }}
            >
              <img
                src={home}
                alt="Home"
                style={{ width: "inherit", maxWidth: "100%" }} // Inherit width from parent Box
              />
            </Box>
          </Grid>
        </Box>

        {/* Second Grid: Positioned at the Top-Right */}
        <Box
          sx={{
            position: "absolute",
            top: { xs: "10px", lg: "30px" }, // Adjust top offset for responsiveness
            right: { xs: "10px", lg: "30px" }, // Adjust right offset for responsiveness
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "end" },
              gap: { xs: "6px", lg: "14px" },
            }}
          >
            <Box
              sx={{
                width: { xs: "20px", lg: "30px" }, // Set width 20px for small, 30px for large screens
              }}
            >
              <a href="https://www.facebook.com/vpickuponsm" target="_blank">
                <img
                  src={fb}
                  alt="Facebook"
                  style={{ width: "inherit", maxWidth: "100%" }} // Inherit width from parent Box
                />
              </a>
            </Box>

            <Box
              sx={{
                width: { xs: "20px", lg: "30px" }, // Set width 20px for small, 30px for large screens
              }}
            >
              <a href="https://www.instagram.com/vpickup_sm/" target="_blank">
                <img
                  src={insta}
                  alt="Instagram"
                  style={{ width: "inherit", maxWidth: "100%" }} // Inherit width from parent Box
                />
              </a>
            </Box>

            <Box
              sx={{
                width: { xs: "20px", lg: "30px" }, // Set width 20px for small, 30px for large screens
              }}
            >
              <a href="https://www.tiktok.com/@vpickupsm" target="_blank">
                <img
                  src={tictok}
                  alt="TikTok"
                  style={{ width: "inherit", maxWidth: "100%" }} // Inherit width from parent Box
                />
              </a>
            </Box>
          </Grid>
        </Box>
        <Box sx={{ display: { xs: "none", lg: "block" } }}>
          <img
            src={undermaintain}
            alt="Coming Soon"
            style={{
              position: "absolute",
              bottom: 0,
              right: "-30px",
              maxWidth: "70%", // Control image size responsiveness
              width: "50%", // Responsive width for larger screens
            }}
          />
        </Box>
        <Grid container>
          <Grid item xs={12} lg={7}>
            <Typography
              sx={{
                color: "#fff", // White text inside sub-container
                fontFamily: "Roboto",
                fontSize: { xs: "36px", md: "50px" }, // Responsive font size
                fontWeight: 700,
                // marginBottom: "20px",
              }}
            >
              Under Maintenance
            </Typography>

            {/* Subtitle */}
            <Typography
              sx={{
                color: "#fff", // White text inside sub-container
                fontFamily: "Roboto",
                fontSize: { xs: "20px", md: "24px" }, // Responsive font size
                fontWeight: 400,
                marginBottom: "50px",
              }}
            >
              We Will Be Back Soon!.
            </Typography>

            {/* Contact Us Button */}
            <Button
              onClick={() => handleNavigation("/help")}
              variant="contained"
              sx={{
                padding: "10px 20px",
                background: "#fff",
                borderRadius: "12px",
                color: "#683C8C",
                textTransform: "capitalize",
                boxShadow: "none",
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: "14px",
                marginBottom: "20px",
                "&:hover": {
                  background: "#fff",
                  color: "#683C8C",
                },
              }}
            >
              Contact Us
            </Button>
            <Typography
              sx={{
                color: "#fff", // White text inside sub-container
                fontFamily: "Roboto",
                fontSize: { xs: "16px", md: "20px" }, // Responsive font size
                fontWeight: 400,
              }}
            >
              Contact: support@vpickup.freshdesk.com
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            sx={{
              textAlign: "center",
              mt: 4,
              display: { xs: "block", lg: "none" },
            }}
          >
            <img
              src={undermaintain}
              alt="Coming Soon"
              style={{
                maxWidth: "70%", // Control image size responsiveness
                width: "70%", // Responsive width for larger screens
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
