export * from "./HomePage";
export * from "./CategoryPage";
export * from "./PrivacyPolicy";
export * from "./TermsOfUse";
export * from "./PromoTerms";
export * from "./Refund";
export * from "./AcceptableItems";
export * from "./OutAreaPolicy";
export * from "./CoverageArea";
export * from "./About";
export * from "./MoreWays";
export * from "./Help";
export * from "./News";
export * from "./IOSApp";
export * from "./AndroidApp";
export * from "./WebApp";
export * from "./CommingSoon";
export * from "./UnderMaintainence";
export * from "./DrivePickUs";
export * from "./FAQs";
export * from "./PartnerWithUs";
export * from "./BusinessClientStory";

